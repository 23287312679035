// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import 'normalize';
@import 'fonts';

$cPrimary: #FFFFFF;
$bgPrimary: #263768;

$cyan: #0094C4;

$content: #000000;
$meta: #585858;
$fond: #F0F0F0;

$timing: 250ms;

*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}
table {
  border-collapse: collapse;
}
textarea {
  white-space: revert;
}


:root {
    --cPrimary: #{$cPrimary};
    --bgPrimary: #{$bgPrimary};
    --cyan: #{$cyan};
    --content: #{$content};
    --meta: #{$meta};
    --fond: #{$fond};
}

*, ::after, ::before {
    box-sizing: border-box;
}

html,
body {
    font-size: 16px;
    line-height: 150%;
    color: $content;
    background-color: $cPrimary;
    font-family: 'Avenir LT Std';
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    // overflow-x: hidden;
    .over {
        overflow: hidden;
    }
}
a {
    cursor: pointer;
}
img,
video,
iframe {
    vertical-align: bottom;
}
h1 {
    margin-bottom: 1.5rem;
    line-height: 2.2rem;
    font-weight: normal;
}
h2 {
    margin-bottom: 1.5rem;
    // color: $meta;
    display: inline-block;
    margin-top: 1rem;
    padding: 0.5em 1rem 0.25rem;
    border: 1px solid $bgPrimary;
    border-radius: 0.25rem;
    color: $bgPrimary;
    text-transform: uppercase;
    font-weight: bold;
}
h3 {
    margin-bottom: 0.5rem;
}
h4 {
    font-size: 1.5rem;
    line-height: 120%;
    font-weight: bold;
    color: $bgPrimary;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    span {
        display: block;
        font-size: 1.25rem;
        font-weight: normal;
        text-transform: none;
    }
}
p {
    margin-bottom: 1rem;
}

.w-100 {
    width: 100%;
}

.ttu {
    text-transform: uppercase;
}
.tar {
    text-align: right;
}
.tal {
    text-align-last: left;
}
.tac {
    text-align: center;
}

.btn {
    &.btn-primary {
        color: $cPrimary;
        background-color: $cyan;
        padding: 0.5rem;
        display: inline-block;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        padding: .375rem .75rem;
        font-size: 1rem;
        border-radius: 0.5rem;
        text-decoration: none;
        text-transform: uppercase;
        transition: $timing;
        &:hover {
            background-color: $bgPrimary;
        }
    }
    &.btn-secondary {
        color: $cPrimary;
        background-color: $meta;
        padding: 0.5rem;
        display: inline-block;
        line-height: 1.5;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        padding: .375rem .75rem;
        font-size: 1rem;
        border-radius: 0.5rem;
        text-decoration: none;
        text-transform: uppercase;
        transition: $timing;
        &:hover {
            background-color: $cyan;
        }
    }
}

// @for $i from 0 to 11 {
//   .regw-#{calc($i*10)} {
//       width: calc($i*10%);
//   }
// }

.box {
    &.box-succes {
        background-color: rgba(0, 255, 0, 0.25);
        border-radius: 0.25rem;
        border: 1px solid rgba(0, 255, 0, 0.75);
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        color: rgb(6, 68, 6);
    }
    &.box-info {
        background-color: rgba(255, 255, 0, 0.3);
        border-radius: 0.25rem;
        border: 1px solid rgba(128, 128, 0, 1);
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        color: rgba(128, 128, 0, 1);
    }
    &.box-error {
        background-color: rgba(255, 0, 0, 0.3);
        border-radius: 0.25rem;
        border: 1px solid rgba(255, 0, 0, 1);
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        color: rgba(255, 0, 0, 1);
        ul {
            list-style-type: none;
        }
    }
}


// input not in page's form 
input {
    border: none;
    font-size: 1rem;
    line-height: 1.2;
    padding: 0.75rem;
    &:focus {
        outline: none;
    }
    &[type="search"],
    &[type="email"],
    &[type="text"] {
        border: 1px solid $cPrimary;
        background-color: $cPrimary;
        color: $content;
    }
    &[type="search"]:focus,
    &[type="email"]:focus,
    &[type="text"]:focus {
        border: 1px solid $cyan;
    }
    &[type="submit"] {
        border: 1px solid $cyan;
        background-color: $cyan;
        color: $cPrimary;
        cursor: pointer;
    }
    &[type="submit"]:focus {
        border: 1px solid $cPrimary;
    }
    &[type="checkbox"] {
        all: revert;
    }
}
button[type="submit"] {
    border: none;
    font-size: 1rem;
    line-height: 1.2;
    padding: 0.75rem;
    border: 1px solid $cyan;
    background-color: $cyan;
    color: $cPrimary;
    cursor: pointer;
}
label span {
    cursor: pointer;
}

// Forms in the pages
.form {
    margin-bottom: 0.5rem;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    select,
    textarea {
        border: 1px solid $meta;
        color: $content;
        border-radius: 0.25rem;
        background-color: $cPrimary;
        color: $content;
        margin-bottom: 1rem;
        width: 100%;
        padding: 0.75rem;
        line-height: 1.2;
        &:focus {
        border: 1px solid $cyan;
        }
    }
    select {
        background-image: url(images/select.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 0.5rem) center;
        option[disabled],
        option:disabled,
        option:first-child {
            color: $meta;
        }
    }
    textarea {
        min-height: 250px;
        resize: vertical;
        vertical-align: bottom;
    }
    input[type="submit"],
    button[type="submit"] {
        border-radius: 0.25rem;
        border: 1px solid $cyan;
        transition: $timing;
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
            border-color: $bgPrimary;
        }
    }
    .submit {
        text-align: right;
    }

    .invalid-feedback {
        background-color: rgba(255, 0, 0, 0.25);
        border-radius: 0.25rem;
        border: 1px solid rgba(255, 0, 0, 0.75);
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        color: red;
    }
}


// Socials links
.socials {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0.25rem 0 0 0;
    .social {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding-right: 0.75rem;
        margin-right: 1rem;
        background-repeat: no-repeat;
        background-position: left center;
        &.rss {
            background-image: url(images/socials/bi_rss-fill.svg);
        }
        &.twitter  {
            background-image: url(images/socials/bi_twitter.svg);
        }
        &.facebook {
            background-image: url(images/socials/bi_facebook-fill.svg);
        }
        &.mail {
            background-image: url(images/socials/bi_mail.svg);
        }
    }
}


header {
    position: relative;
    z-index: 100;
    .main-header,
    .secondary-header {
        padding: 0 1rem;
        min-height: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        position: relative;
    }
    .main-header {
        .right,
        .left {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: stretch;
            a {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
            }
        }
        .left {
            .burger {
                margin-right: 1rem;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                border: 2px solid $bgPrimary;
                width: 20px;
                height: 20px;
                padding: 2px;
                cursor: pointer;
                overflow: hidden;
                position:relative;
                transition: $timing;
                .line {
                    background-color: $bgPrimary;
                    width: 100%;
                    height: 2px;
                    transition: $timing;
                    &:nth-child(2) {
                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            top: 8px;
                            right: 0;
                            height: 2px;
                            width: 30px;
                            padding: 0;
                            transform-origin: center center;
                            opacity: 0;
                            transition: $timing;
                        }
                        &::before {
                            transform: rotate(
                        45deg);
                            left: -6px;
                            background-color: $bgPrimary;
                        }
                        &::after {
                            transform: rotate(-
                        45deg);
                            left: -8px;
                            background-color: $bgPrimary;
                        }
                    }
                }
                &.show {
                    border-color: transparent;
                    .line {
                        background-color: transparent;
                        &:nth-child(2) {
                            &::before,
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .logo img {
                height: 2rem;
            }
        }
        .right {
            .account a svg {
                width: 1.75rem;
                height: 1.75rem;
                fill: $cyan;
                transition: $timing;
            }
            .account a:hover svg {
                fill: $bgPrimary;
            }
            .subscription {
                border-left: 2px solid $bgPrimary;
                padding-left: 0.75rem;
                margin-left: 0.75rem;
            }
        }
    }
    .mobile_menu {
        position: absolute;
        z-index: 1000;
        top: 50px;
        right: 0;
        left: 0;
        padding-top: 1rem;
        transform: translateX(-100%);
        transition: $timing;
        background-color: $cPrimary;
        color: $meta;
        // box-shadow: 0 20px 20px $meta;
        text-transform: uppercase;
        height: calc(100vh - 50px);
        overflow: scroll;
        a {
            color: $meta;
            text-decoration: none;
        }
        &.show {
            transform: translateX(0);
        }
        nav ul {
            margin: 0;
            padding: 0;
        }
        nav ul li {
            border-bottom: 1px solid $meta;
            padding: 0.35rem 0.5rem;
            background-image: url(images/menu-arrow.svg);
            background-repeat: no-repeat;
            background-position: calc(100% - 0.5rem) center;
            &:not(.follow):hover {
                background-image: url(images/menu-arrow-hover.svg);
            }
        }
        nav ul li a {
            display: block;
        }
        nav ul li ul li {
            border-bottom: none;
            background-image: none;
        }
        nav ul li ul li a {
            padding-left: 3rem;
        }
        nav ul li ul li:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
        nav .follow {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            align-content: stretch;
            background-image: none;
            a {
                width: 20px;
                height: 20px;
                margin-left: 1rem;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                &.twitter {
                    background-image: url(images/our-twitter.svg);
                }
                &.facebook {
                    background-image: url(images/our-facebook.svg);
                }
            }
        }
        nav ul li.categories {
            background-color: $fond;
            background-image: none;
            padding-right: 0;
            &:hover,
            li,
            li li {
                background-image: none;
            }
        }

    }
    .secondary-header {
        background-color: #ccc;
        text-transform: uppercase;
        nav {
            width: 100%;
        }
        nav ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }
        a {
            text-decoration: none;
            color: $meta;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }
        a:hover {
            color: $cyan;
        }
        nav svg {
            width: 1.25rem;
            height: 1.25rem;
        }
        .home a svg path,
        .search a svg path {
            fill: $meta;
        }
        .home a:hover svg path,
        .search a:hover svg path {
            fill: $cyan;
        }
        #search__content {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            transform: translateY(-100%);
            opacity: 0;
            transition: $timing;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: stretch;
            padding: 1.5rem;
            color: $cPrimary;
            text-align: center;
            z-index: -1;
            background-color: $bgPrimary;
            text-transform: none;
            &.show {
                transform: translateY(0);
                opacity: 1;
                z-index: 1;
            }
            form {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                align-items: stretch;
                width: 100%;
                margin-right: 1rem;
                input[type="search"] {
                    position: relative;
                    flex: 1 1 auto;
                    width: 1%;
                    min-width: 0;
                }
            }
            .close {
                display: block;
                width: 1rem;
                height: 1rem;
                background-image: url(images/search-close.svg);
                background-repeat: no-repeat;
                background-position: center center;
                cursor: pointer;
            }
        }
    }
}

main {
    padding: 0.5rem;
    // user-select: none;
    .page,
    .live-article,
    .categorie-all,
    .categorie-article {
        &.page-404 {
            background-image: url(images/404_bg.png);
            background-repeat: no-repeat;
            background-position: right 5rem;
            background-size: 25%;
        }
    }
    .page {
        &.offline {
            min-height: 50vh;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
        }
        &.dashboard {
            min-height: 50vh;
        }
        &.page-weather .weather {
            min-height: calc(100vh - 138px);
            background-color: $cyan;
            color: $cPrimary;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            .weather_content {
                padding-top: 4rem;
            }
            h3 {
                line-height: 100%;
            }
            h3,
            .weather_content_temp {
                font-size: 4rem;
                text-transform: uppercase;
                margin-bottom: 1rem;
            }
            .weather_content_description {
                margin-bottom: 2rem;
            }
            .weather_content_minmax {
                margin-bottom: 0;
            }
            .temp_min {
                padding-right: 0.5rem;
            }
            .temp_max {
                padding-left: 0.5rem;
            }
        }
        h3 {
            font-size: 1.5rem;
        }
    }
    .article {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid $meta;
        .visuel,
        h1,
        .content,
        .author,
        .readtime,
        .when {
            margin-bottom: 1rem;
        }
        .metas {
            font-size: 0.9rem;
        }
        .author a {
            color: $bgPrimary;
            text-decoration: underline;
            &:hover {
                color: $cyan;
            }
        }
        .readtime,
        .when {
            color: $meta;
        }
        .readtime {
            background-image: url(images/readtime.svg);
            padding: 0.75rem 0 0.5rem 2.75rem;
            background-repeat: no-repeat;
            background-position: center left;
            background-size: 2rem;
        }
        .sharer ul {
            list-style-type: none;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;
            align-content: center;
            li {
                margin-left: 1rem;
                a {
                    display: block;
                    width: 2rem;
                    height: 2rem;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    transition: $timing;
                    &.facebook {
                        background-image: url(images/socials/share/facebook.svg);
                        &:hover {
                            background-image: url(images/socials/share/facebook-hover.svg);
                        }
                    }
                    &.twitter {
                        background-image: url(images/socials/share/twitter.svg);
                        &:hover {
                            background-image: url(images/socials/share/twitter-hover.svg);
                        }
                    }
                    &.mail {
                        background-image: url(images/socials/share/mail.svg);
                        &:hover {
                            background-image: url(images/socials/share/mail-hover.svg);
                        }
                    }
                    &.bookmark {
                        background-image: url(images/socials/share/bookmark.svg);
                        &:hover {
                            background-image: url(images/socials/share/bookmark-hover.svg);
                        }
                    }
                }
            }
        }
    }
    .article_resume {
        margin-bottom: 1rem;
        a {
            color: $content;
            text-decoration: none;
        }
        .article_resume__visuel {
            max-width: 25%;
            float: left;
            margin-right: 1rem;
        }
        .article_resume__title,
        .article_resume__metas {
            margin-left: calc(25% + 1rem);
        }
        .article_resume__metas {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: stretch;
            align-content: center;
            font-size: 0.9rem;
        }
        .article_resume__metas_author a {
            color: $bgPrimary;
            text-decoration: underline;
            &:hover {
                color: $cyan;
            }
        }
        .article_resume__metas_publish {
            color: $meta;
            white-space: nowrap;
        }
        &::after {
            display: block;
            clear: both;
            content: "";
        }
    }
    .ui.pagination.menu {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: space-around;
        .item {
            padding: 0 0.25rem;
            text-decoration: none;
            color: $bgPrimary;
            cursor: pointer;
            &.icon:not(.disabled) {
                background-image: url(images/menu-arrow.svg);
                background-repeat: no-repeat;
                width: 1rem;
                height: 1rem;
                background-position: center center;
                &[rel=prev] {
                    transform: rotate(180deg);
                }
                &:hover {
                    background-image: url(images/menu-arrow-hover.svg);
                }
            }
            &:hover:not(.disabled) {
                color: $cyan;
            }
        }
    }

    .home {
        .live-article {
            .article {
                border-bottom: none;
            }
        }
        .ombre {
            position: relative;

            &::after {
                position: absolute;
                pointer-events: none;
                left: calc(100% - 2.5rem);
                top: 0;
                bottom: 1rem;
                content:'';
                width: 3rem;
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.75) 50%, rgba(255,255,255,1) 100%);
            }
        }
        .article-resume-horizontal {
            overflow-x: scroll;
            overflow-y: hidden;
            width: calc(100vw - 0.5rem);
            .dflex {
                display: flex;
                flex-direction: row;
            }
            .article {
                min-width: calc(95vw - 1rem);
                float: left;
                padding-right: 1rem;
                margin-bottom: 0.5rem;
                position: relative;
                &:last-child {
                    margin-right: 3rem;
                }
                .article_resume__visuel {
                    margin-bottom: 0.5rem;
                    .tag {
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: $cyan;
                        color: $cPrimary;
                        padding: 0.25rem 1rem 0.25rem;
                        font-size: 0.95em;
                        border-bottom-right-radius: 1rem;
                    }
                }
                .article_resume__metas {
                    font-size: 0.9rem;
                }
                .article_resume__metas_author a {
                    color: $bgPrimary;
                    &:hover {
                        color: $cyan;
                    }
                }
            }
        }
        .categories {
            .categorie {
                border-bottom: 1px solid $meta;
                padding-bottom: 1rem;
            }
        }
    }
}



footer {
    padding: 0.75rem;
    background-color: $bgPrimary;
    color: white;
    line-height: 120%;
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            padding-top: 0.75rem;
        }
    }
    a,
    h2 {
        color: white;
        text-decoration: none;
        font-size: 1rem;
        transition: $timing;
    }
    h2 {
        display: block;
        border: none;
        text-transform: uppercase;
        font-weight: 700;
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
        padding: 0.25rem;
    }
    a:hover {
        color: $cyan;
    }
    .bloc {
        border-bottom: 1px solid white;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }
    .bloc:last-child {
        padding-bottom: 0.5rem;
    }
    .bloc h2:last-child {
        margin-bottom: 0;
    }
    .bloc ul:last-child {
        margin-bottom: 0;
    }
    .bloc:last-child{
        border-bottom: none;
        margin-bottom: 0;
    }
}


@media screen and (max-width: 480px) {
    header .secondary-header a {
        font-size: 0.80rem;
    }
}
@media screen and (max-width: 340px) {
    header .secondary-header a {
        font-size: 0.60rem;
    }
}

@media screen and (min-width: 920px) {
    header .mobile_menu {
        right: auto;
    }
    main .home .article-resume-horizontal .article {
        min-width: calc(50vw - 1rem);
    }
    main {
        .articles_content {
            display: flex;
            flex-wrap: wrap;
        }
        .article_resume {
            width: calc(50% - 0.5rem);
            &:nth-child(2n+1) {
                margin-right: 1rem;
            }
        }
    }
    .categories {
        .categorie {
            .categorie_content_item {
                display: flex;
                .article_resume {
                    width: calc(50% - 0.5rem);
                    margin-right: 1rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    footer {
        .container {
            display: flex;
            .bloc {
                width: 33%;
                border-bottom: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}



@media screen and (min-width: 1024px) {
    header .main-header,
    header .secondary-header nav.container,
    main .container .categories,
    main .container .categorie-article,
    main .container .page,
    main .container .article,
    main .container .article-next,
    main .container .articles,
    main .container .auth {
        width: 1024px;
        margin: 0 auto;
    }
    header .main-header {
        padding: 0;
    }
    main .home .article-resume-horizontal .article {
        min-width: calc(33.333333333vw - 1rem);
    }
    footer {
        .container {
            justify-content: center;
            .bloc {
                width: 25%;
            }
        }
    }
}
/* */



@media screen and (min-width: 1280px) {
    main .home .article-resume-horizontal {
        width: 100%;
    }
    main .home .article-resume-horizontal .article {
        min-width: calc(20% - 0.5rem);
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        padding: 0;
    }
    main .home .ombre::after {
        display: none;
    }
    main .home .article-resume-horizontal .article:last-child {
        padding: 0;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
    main .home .article-resume-horizontal .article {

    }


    footer {
        .container {
            .bloc {
                width: 20%;
            }
        }
    }
}