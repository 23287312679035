@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/AvenirLTStd-Roman.eot');
    src: url('fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Roman.woff') format('woff'),
        url('fonts/AvenirLTStd-Roman.ttf') format('truetype'),
        url('fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/AvenirLTStd-Book.eot');
    src: url('fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Book.woff') format('woff'),
        url('fonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir LT Std';
    src: url('fonts/AvenirLTStd-Black.eot');
    src: url('fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/AvenirLTStd-Black.woff2') format('woff2'),
        url('fonts/AvenirLTStd-Black.woff') format('woff'),
        url('fonts/AvenirLTStd-Black.ttf') format('truetype'),
        url('fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

